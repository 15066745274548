import * as React from "react";
import PropTypes from "prop-types";

const AboutParagraphsGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item, key) => (
      <p key={key} className="jparagraph">{item.text}</p>
    ))}
  </div>
);

AboutParagraphsGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  )
};

export default AboutParagraphsGrid;
